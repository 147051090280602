import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilCalendar,
  cilPencil,
  cilTrash,
  cilExternalLink,
  cilZoom,
  cilAddressBook,
  cilPlus,
  cilCalculator,
  cilFile,
  cilChevronBottom,
  cilChevronTop
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilCalendar,
    cilPencil,
    cilTrash,
    cilExternalLink,
    cilZoom,
    cilAddressBook,
    cilPlus,
    cilCalculator,
    cilFile,
    cilChevronBottom,
    cilChevronTop
  }
);
